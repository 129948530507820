import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import styles from "../styles/post.module.scss";

import translationImage from "./images/law-photography-translation-github.png";

const ContributionPage = () => (
  <Layout>
    <SEO title="Contributing to law.photography" />
    <div className={styles.postContainer}>
      <article className={styles.post}>
        <h1>Contributing</h1>
        <p>
          You can contribute to law.photography by clicking the{" "}
          <strong>Edit this page</strong> button at the bottom of every page and
          do so using your GitHub account.
        </p>
        <p>
          All content is licensed under a creative commons license, so you can
          keep a copy or start your own website with the content any time.
        </p>
        <h2>General Guidelines:</h2>
        <p>
          To make sure we can keep a high quality of content, please read and
          follow below guidelines. If all of the below is too techie, you can
          send us any contributions (either your local country laws or a
          translation) by email to:{" "}
          <a href="mailto:hello@gegenwind.dk">hello@gegenwind.dk</a> or{" "}
          <a href="https://www.facebook.com/GegenWind.dk/">
            write us on facebook
          </a>
          .
        </p>
        <h3>Directory Structure</h3>
        <p>
          The repository is structured by law, country category of legislation
          and language like so:
        </p>
        <ul>
          <li>laws</li>
          <ul>
            <li>Denmark</li>
            <ul>
              <li>street.md</li>
              <li>drones.md</li>
              <li>drones.da.md</li>
            </ul>
            <li>Germany</li>
            <ul>
              <li>street.md</li>
              <li>street.de.md</li>
              <li>drones.md</li>
              <li>permits.md</li>
            </ul>
          </ul>
        </ul>
        <h3>Research Your Edits</h3>
        <p>
          Even though this site is not official legal advice, we aim to be a s
          precise as possible by both citing laws and previous court cases
          including their rulings. This is because a lot of countries have
          precedence influenced justice systems where a law gets applied by
          judges and the history of previous cases can matter to the
          interpretation of the law.
        </p>
        <p>
          Link to your sources, government law pages preferred, and keep the
          source of information transparent.
        </p>
        <h3>Apply Meta Fields</h3>
        <p>
          When editing some of the files you'll see there are some additional
          fields on top of the text. This format is called{" "}
          <strong>frontmatter</strong> and is used to display titles, last
          updated dates and to categorise the content to the correct
        </p>
        <ul>
          <li>country</li>
          <li>type of law</li>
          <li>language</li>
        </ul>
        <p>
          Please keep the frontmatter annotation intact and update where
          necessary. Here's an example:
        </p>
        <pre>
          <code>---</code>
          <code>title: "Street Photography Laws in Austria"</code>
          <code>date: "2019-01-01"</code> <code>lastmod: "2019-01-01"</code>{" "}
          <code>
            description: "What's legal to shoot in Austria doing street
            photography and where?"
          </code>
          <code>---</code>
        </pre>
        <p>
          If you're unsure how to edit this, just don't and well take care of
          it!
        </p>
        <h3>Translation</h3>
        <p>
          We aim for this site to be international, so if your native language
          is any other than English, you can translate the content by going to
          the{" "}
          <a
            href="https://github.com/GegenWindPhoto/law.photography-content"
            target="_blank"
            rel="noopener noreferrer"
          >
            Github repository
          </a>{" "}
          and creating a new file in the already existing pattern. So if
          "street.md" is the base file, for Spanish you'd create:
          "street.es.md". The language format is{" "}
          <a
            href="https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes"
            target="_blank"
            rel="noopener noreferrer"
          >
            ISO 639-1
          </a>
          .
        </p>
        <p>
          <img src={translationImage} alt="translation github" />
        </p>
      </article>
    </div>
  </Layout>
);

export default ContributionPage;
